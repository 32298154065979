body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


#whatsapp {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 2000 !important;
}

#whatsapp2 {
  width: 80px;
  filter: drop-shadow(0 1px 4px rgba(0, 0, 0, .4));
}

#whatsapp4 {
  fill: #25d366;
}

#whatsapp3 {
  fill: #fff;
}
